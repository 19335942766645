import Router from '../router'

export const cattelService = {
    addCow,
    UploadCsv,
    OneNCsv,
    UploadCsvItem,
    OneNCsvItem,
    editCow,
    deleteCow,
    getCow,
    AllDelete,
    AllPostion,
    searchCattels,
    getCattels,
    cowAccess,
    getEvents
};

export const posService = {
    getPos,
    editPos,
    addPos,
    deletePos,
    getPositions,
};

export const ioncService = {
    addIonc,
    getInoc,
    deleteInoc,
    editInoc,
};

export const birthService = {
    addBirth,
    getBirth,
    deleteBirth,
    editBirth,
};

export const visitService = {
    addVisit,
    getVisit,
    deleteVisit,
    editVisit,
};

export const dryService = {
    addDry,
    getDry,
    deleteDry,
    editDry,
};

export const milkrecordService = {
    addMilkrecord,
    getMilkrecord,
    deleteMilkrecord,
    editMilkrecord,
};

export const bodyService = {
    addBodys,
    getBodys,
    deleteBodys,
    editBodys,
};

export const motionService = {
    addMotion,
    getMotion,
    deleteMotion,
    editMotion,
};

export const illnesService = {
    addIllnes,
    getIllnes,
    deleteIllnes,
    editIllnes,
};

function addCow(Cownum, Birthtime, Birthsnum, LastBirthtime, LastInoculatedtime, LastDrytime, PregnancyStatus, CowType, Position, RegistrNum, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "token": auth.token,
            "cow_num": Cownum,
            "birthtime": Birthtime,
            "births_num": Birthsnum,
            "last_birthtime": LastBirthtime,
            "last_inoculatedtime": LastInoculatedtime,
            "last_drytime": LastDrytime,
            "pregnancy_status": PregnancyStatus,
            "cow_type": CowType,
            "position_id": Position,
            "registr_num": RegistrNum,
            "description": Description,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/addcow`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function UploadCsv(CsvData) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "token": auth.token,
            "upload": CsvData
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/upload`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function OneNCsv(CowId, CsvData) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "token": auth.token,
            "id": CowId,
            "ncow": CsvData
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/uploadncow`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function UploadCsvItem(DataType, CsvData) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "token": auth.token,
            "type": DataType,
            "upload": CsvData
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/uploaditem`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function OneNCsvItem(CowId, Type, CsvData) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "token": auth.token,
            "id": CowId,
            "type": Type,
            "ndata": CsvData
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/uploadncowitem`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editCow(CowID, Cownum, Birthtime, CowType, Position, RegistrNum, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": CowID,
            "cow_num": Cownum,
            "birthtime": Birthtime,
            "cow_type": CowType,
            "position_id": Position,
            "registr_num": RegistrNum,
            "description": Description,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/editcow`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteCow(id) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/deletecow`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getCow(id) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/getcow`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function AllDelete(ids) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "ids": ids,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/alldeletecow`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function AllPostion(ids, posId) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "token": auth.token,
            "ids": ids,
            "pos_id": posId,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/allposcow`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function searchCattels(Count, From, Text) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "count": Count,
            "from": From,
            "text": Text
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/search`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getCattels(Count, From) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "count": Count,
            "from": From
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function cowAccess(cow_id) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cow_id
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/cowaccess`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getEvents(id) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/cattel/getevents`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getPos(id) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/position/getpos`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editPos(id, posname, desc) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id,
            "psname": posname,
            "description": desc
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/position/editpos`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addPos(posname, des) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "psname": posname,
            "description": des
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/position/addpos`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deletePos(id, cid) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": id,
            "cid": cid
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/position/deletepos`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getPositions() {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/position`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addIonc(cowID, InocTime, InocRound, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cowID,
            "inoculate_time": InocTime,
            "inoculate_round": InocRound,
            "description": Description
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/inoculated/addinoc`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getInoc(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/inoculated/getinoc`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteInoc(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/inoculated/deleteinoc`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editInoc(cowID, data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
            "data": data,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/inoculated/editinoc`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addBirth(cowID, Birthtime, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cowID,
            "birthtime": Birthtime,
            "description": Description
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/birth/addbirth`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getBirth(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/birth/getbirth`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteBirth(Id, cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": Id,
            "cow_id": cowID,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/birth/deletebirth`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editBirth(cowID, data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
            "data": data,
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/birth/editbirth`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addVisit(cowID, Visittime, Visitresult, Visitreason, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cowID,
            "visittime": Visittime,
            "visitresult": Visitresult,
            "visitreason": Visitreason,
            "description": Description
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/visit/addvisit`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getVisit(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/visit/getvisit`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteVisit(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/visit/deletevisit`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editVisit(cowID, data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
            "data": data
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/visit/editvisit`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addDry(cowID, Drytime, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cowID,
            "drytime": Drytime,
            "description": Description
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dry/adddry`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getDry(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dry/getdry`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteDry(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dry/deletedry`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editDry(cowID, data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
            "data": data
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/dry/editdry`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addMilkrecord(cowID, Recorddate, Weight, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cowID,
            "recorddate": Recorddate,
            "weight": Weight,
            "description": Description
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/milkrecord/addmilkrecord`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getMilkrecord(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/milkrecord/getmilkrecord`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteMilkrecord(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/milkrecord/deletemilkrecord`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editMilkrecord(cowID, data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
            "data": data
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/milkrecord/editmilkrecord`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addBodys(cowID, Scoretime, BCS, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cowID,
            "scoretime": Scoretime,
            "bcs": BCS,
            "description": Description
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/body/addbodys`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getBodys(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/body/getbodys`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteBodys(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/body/deletebodys`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editBodys(cowID, data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
            "data": data
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/body/editbodys`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addMotion(cowID, Scoretime, MovementNum, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cowID,
            "scoretime": Scoretime,
            "movement_num": MovementNum,
            "description": Description
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/motion/addmotion`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getMotion(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/motion/getmotion`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteMotion(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/motion/deletemotion`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editMotion(cowID, data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
            "data": data
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/motion/editmotion`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function addIllnes(cowID, Entrydate, Reasonentry, Departuredate, Description) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "cow_id": cowID,
            "entrydate": Entrydate,
            "reasonentry": Reasonentry,
            "departuredate": Departuredate,
            "description": Description
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/illnes/addillnes`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function getIllnes(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/illnes/getillnes`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function deleteIllnes(cowID) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/illnes/deleteillnes`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function editIllnes(cowID, data) {
    let auth = JSON.parse(localStorage.getItem('auth'));
    const requestOptions = {
        method: 'POST',

        body: JSON.stringify({
            "token": auth.token,
            "id": cowID,
            "data": data
        })
    };
    return fetch(process.env.VUE_APP_BACKEND_URL + `/illnes/editillnes`, requestOptions).then(handleResponse).then(data => {
        return data
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('auth');
                location.reload();
            }
            if (response.status === 500 || response.status === 503) {
                Router.push({
                    path: "/error-500",
                    redirect: {
                        name: "Error-500"
                    },
                });
            }
        }
        return data;
    });
}